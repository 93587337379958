import React, { useEffect, useState } from 'react';
import { getNamesByGender, updateNames } from '../../actions/nameAction';
import { TablePagination } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader';

const CorrectNames = () => {
  const [allNames, setAllNames] = useState([]);
  const [editableNameIndex, setEditableNameIndex] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [status, setStatus] = useState([]);
  const [gender, setGender] = useState('male');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [isLoading, setLoader] = useState(false);

  // Fetch names based on selected gender
  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await getNamesByGender(gender);
      const uniqueNames = [...new Set(response)];
      console.log('Unique names count:', uniqueNames?.length);
      setAllNames(uniqueNames);
      setStatus(Array(uniqueNames.length).fill('')); // Initialize status for all names
    } catch (error) {
      toast.error('Failed to fetch names. Please try again.');
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [gender]);

  // Handle name edit initiation
  const handleEditClick = (index, name) => {
    setEditableNameIndex(index);
    setEditedName(name);
  };

  // Cancel name editing
  const handleCancelEdit = () => {
    setEditableNameIndex(null);
  };

  // Handle key press for updating the name
  const handleKeyPress = async (event, index) => {
    if (event.key === 'Enter') {
      const globalIndex = page * rowsPerPage + index;

      try {
        setStatus((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[globalIndex] = 'Updating...';
          return newStatus;
        });

        const data = {
          old_field_value: allNames[globalIndex],
          new_field_value: editedName.trim(),
        };
        const response = await updateNames(data);

        if (response?.status) {
          setAllNames((prevNames) => {
            const updatedNames = [...prevNames];
            updatedNames[globalIndex] = editedName.trim();
            return updatedNames;
          });

          setStatus((prevStatus) => {
            const updatedStatus = [...prevStatus];
            updatedStatus[globalIndex] = 'Updated';
            return updatedStatus;
          });

          toast.success('Name updated successfully.');
        } else {
          toast.error('Update failed. Please try again.');
        }
      } catch (error) {
        toast.error('An error occurred while updating the name.');
        console.error(error);
        setStatus((prevStatus) => {
          const errorStatus = [...prevStatus];
          errorStatus[globalIndex] = 'Error occurred...';
          return errorStatus;
        });
      } finally {
        setEditableNameIndex(null);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <label style={{ marginBottom: '10px', display: 'block' }}>
        Gender:
        <div style={{ marginTop: '5px' }}>
          <input
            type="radio"
            value="male"
            checked={gender === 'male'}
            onChange={() => setGender('male')}
            style={{ marginRight: '5px' }}
          />
          <span style={{ marginRight: '15px' }}>Male</span>
          <input
            type="radio"
            value="female"
            checked={gender === 'female'}
            onChange={() => setGender('female')}
            style={{ marginRight: '5px' }}
          />
          <span style={{ marginRight: '15px' }}>Female</span>
        </div>
      </label>

      {isLoading ? (
        <LoadingSpinner message="Loading names..." />
      ) : (
        <>
          <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {allNames.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((name, index) => {
                const globalIndex = page * rowsPerPage + index;
                return (
                  <tr key={name}>
                    <td>{globalIndex + 1}</td>
                    <td>
                      {editableNameIndex === globalIndex ? (
                        <>
                          <input
                            type="text"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e, index)}
                            disabled={status[globalIndex] === 'Updating...'}
                            autoFocus
                          />
                          <button
                            onClick={handleCancelEdit}
                            style={{
                              marginLeft: '10px',
                              padding: '5px',
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <span
                          onClick={() => handleEditClick(globalIndex, name)}
                          style={{ cursor: 'pointer', color: 'blue' }}
                        >
                          "{name}"
                        </span>
                      )}
                    </td>
                    <td>{status[globalIndex]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}

      <TablePagination
        component="div"
        count={allNames.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[100, 200, 500, 1000]}
      />
    </div>
  );
};

export default CorrectNames;
